import React from "react"
import Layout from "../shared/Layout2"
import SEO from "../shared/seo"
import { css } from '@emotion/css'
import NavbarNew from "../shared/NavbarNew"

const Card = (props) => {

    console.log(props.cmsData?.lines);
    
    return <div

        className={css`
            width: 80%;
            background: white;
            border-radius: 30px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 23px 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 815px;
            
            @media (max-width: 630px) {
                width: 100%;
                height: 100%;
                flex-direction: column;
                border-radius: 0;
            }
            `}
        >
        <div
            className={css`
                text-align: right;
            
                @media (max-width: 630px) {
                    text-align: center;
                    padding: 15px;
                }
            `}
        >
            <h1>{props.cmsData.title}</h1>
            <br />
            {props.cmsData?.lines?.map(text => {
                return <h2>{text.text}</h2>
            })}
            <br />
            <h2>{props.cmsData.personName}</h2>
            <h3>{props.cmsData.role}</h3>
        </div>
        <div
            className={css`
        width: 400px;
    
    @media (max-width: 630px) {
        width: 250px;
      }`}

        >
            <img
                className={css`
            border-radius: 30px;
            margin-bottom: -7px;
        
        @media (max-width: 630px) {
            border-radius: 0;
          }`}
                src={props.cmsData.personImage.childImageSharp.fluid.src} alt="daniel" />
        </div>
    </div>
}

const SuccessC = ({cmsData}) => (
    <Layout>
        <NavbarNew darkMode />
        <SEO 
            title={cmsData.seo.title == "-" ? "" : cmsData.seo.title}
            description={cmsData.seo.descr == "-" ? "" : cmsData.seo.descr}
        />

        <div
            className={css`
                    width: 100vw;
                    height: 1000px;
                    background-image: url(${cmsData.bgImage.childImageSharp.fluid.src});
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;

                    @media (max-width: 1000px) {
                        height: 850px;
                    }
                `}
        >

            <div className={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    background: rgb(255,255,255);
                    background: linear-gradient(180deg, rgba(255,255,255,0.1801764651172969) 0%, rgba(255,255,255,0.7123893502713585) 22%, rgba(255,255,255,1) 73%);
                `}>
                <Card cmsData={cmsData}/>
            </div>
        </div>
    </Layout >
)

export default SuccessC
